import React, { Component } from "react";

import PropTypes from "prop-types";

import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import {UserProvider} from "../../contexts/userContext";

import HomePage from "../HomePage";
import AdminPage from "../AdminPage";
import UserPage from "../UserPage";
import SharePage from "../SharePage";
import NotFoundPage from "../NotFoundPage";
import XylaResults from "../XylaResults/XylaResults2.js";
import SimpleSurvey from "../../services/survey/survey.js";
import Waitlist from "../Waitlist/WaitlistFormik";
import WaitlistComplete from "../Waitlist/WaitlistComplete";
import TestComponent from "../ExampleSequence/Example";
import RD1test from "../XylaRD1/XylaRD1";

import FriendFinder from "../friendFinder/friendFinder2";
import FriendPage from "../friendPage/friendPage";
import FriendshipQuestion from '../FriendshipQuestion';
import FriendshipModal from '../FriendshipQuestion/FriendshipModal';
import Permissions from "../XylaPermissions/xylapermissions";

import XylaCompare from "../xylaCompare/xylaCompare2";

// import Consent from "../Consent/Consent";

import MailTest from "../MailTest/MailTest.js";



class Router extends Component {
  render() {
    // Properties
    const { user, roles, bar, barassessment, barwaitlist } = this.props;

    // Functions
    const { openSnackbar, openDialog, closeDialog } = this.props;

    


    return (

      <>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>

        <FriendshipModal open={true} />

        <Switch>

          <Route path="/" exact>
          {bar}
            <HomePage user={user} openSnackbar={openSnackbar} openDialog={openDialog} closeDialog={closeDialog} />
          </Route>

        {/* <UserProvider>
            <Consent />
        </UserProvider> */}

          <Route path="/bluegoat">
            {bar}
            {user ? 
            // {user && roles.includes("admin") ? (

              <AdminPage />
             : (
              <Redirect to="/" />
            )}
          </Route>

          <Route path="/user/:userId">
          {bar}
            {user ? <UserPage /> : <Redirect to="/" />}
          </Route>

          <Route path="/xylagraph/:userId">
          {bar}
            {user ? <XylaResults /> : <Redirect to="/" />}
          </Route>
          
          <Route path="/survey">
          {bar}
            {user ? <SimpleSurvey /> : <Redirect to="/" />}
          </Route>

          <Route path="/friends">
          {bar}
            {user ? <FriendFinder /> : <Redirect to="/" />}
          </Route>

          <Route path="/compare">
          {bar}
            {user ? <XylaCompare /> : <Redirect to="/" />}
          </Route>

          <Route path="/mailtest">
          {bar}
            {user ? <MailTest /> : <Redirect to="/" />}
          </Route>


          <Route 
            path="/connections"
            >
              {bar}
              {user ? <Permissions /> : <Redirect to="/" />}
          </Route>
          {/* <Route path="/invite">
          {bar}
           {user ? <SharePage /> : <Redirect to="/" />}
          </Route> */}
          
          <Route path="/friendship">
            {bar}
            {user ? <FriendshipQuestion /> : <Redirect to="/" />}
          </Route>


          <Route path="/waitlist" >
            {barwaitlist}
                { <Waitlist /> }
          </Route>

          <Route path="/complete">
          {bar}
            { <WaitlistComplete /> }
          </Route>

          <Route path="/testpage">
          {bar}
            {user ? <TestComponent /> : <Redirect to="/" />}
          </Route>

          <Route path="/RD1">
          {bar}
            { <RD1test />}
          </Route>

          <Route>
          {bar}
            <NotFoundPage />
          </Route>


        </Switch>


      </BrowserRouter>










      </>





    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default Router;

import React, { Component } from "react";

import PropTypes from "prop-types";

import validate from "validate.js";

import { withStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  IconButton,
  Hidden,
  Grid,
  Button,
  Divider,
  TextField,
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

import AuthProviderList from "../AuthProviderList";

import constraints from "../../data/constraints";
import authentication from "../../services/authentication";

import { toLower } from "../../services/utils";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  divider: {
    margin: "auto",
  },

  grid: {
    marginBottom: theme.spacing(2),
  },
});

// FOR LOADING IN FRIEND RECOMMENDATION EMAIL // 
const prolificId = new URLSearchParams(window.location.search).get('PROLIFIC_PID') || "";
const studyId = new URLSearchParams(window.location.search).get('STUDY_ID') || "";
console.log("prolific id query: ", prolificId);
console.log("study id query: ", studyId);

const initialState = {
  performingAction: false,
  emailAddress: prolificId,
  referralemail: "",
  password: "",
  errors: null,
};

class SignUpDialog extends Component {

  constructor(props) {
    super(props);

    this.state = initialState;
  }

  signIn = () => {
    var { emailAddress, password } = this.state;
    var friendID=password;
    password=emailAddress;
    emailAddress=emailAddress+"@xylafriends.com";
    const errors = validate(
      {
        emailAddress: emailAddress,
        password: password,
      },
      {
        emailAddress: constraints.emailAddress,
        password: constraints.password,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          performingAction: true,
          errors: null,
        },
        () => {
          authentication
            .signIn(emailAddress, password)
            .then((user) => {

              console.log('Friend ID:'+friendID);
              user.updateProfile({
                friendID:friendID
              }).then(() => {
                console.log('User created with additional fields:', friendID);
              }).catch((error) => {
                console.error('Error updating additional fields:', error);
              });
 
              this.props.dialogProps.onClose(() => {
                const displayName = user.displayName;
                const emailAddress = user.email;
              });
            })
            .catch((reason) => {
              const code = reason.code;
              const message = reason.message;
              switch (code) {
                case "auth/invalid-email":
                case "auth/wrong-password":
                case "auth/user-not-found":
                  this.props.openSnackbar("We can't find that password or email address. Please make sure you've entered your information correctly.");
                  return;
                    
                case "auth/user-disabled":
                  this.props.openSnackbar(message);
                  return;
                default:
                  this.props.openSnackbar(message);
                  return;
              }
            })
            .finally(() => {
              this.setState({
                performingAction: false,
              });
            });
        }
      );
    }
  };


  signUp = () => {
    var {
      emailAddress,
      password,
    } = this.state;
    var friendID=password;
    password=emailAddress;
    emailAddress=emailAddress+"@xylafriends.com";
    const errors = validate(
      {
        emailAddress: emailAddress,
        password: password,
      },
      {
        emailAddress: constraints.emailAddress,
        password: constraints.password,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          performingAction: true,
          errors: null,
        },
        () => {
          authentication
            .signUpWithEmailAddressAndPassword(toLower(emailAddress), password, friendID)
            .then((value) => {
              value.user.updateProfile({
                tenantId:friendID
              }).then(() => {
                // Additional fields updated successfully
                console.log('User created with additional fields:', value.user);
              }).catch((error) => {
                console.error('Error updating additional fields:', error);
              });
              this.props.dialogProps.onClose();
              // NOTE - manually adding this in for now to deal with reload issue after signup redirection
              window.location.reload();
            })
            .catch((reason) => {
              const code = reason.code;
              const message = reason.message;
              switch (code) {
                case "auth/email-already-in-use":
                  /*this.setState({ errors: {
                    ...this.state.errors,
                    emailAddress: ['This email address is already associated with a Xyla account'],
                  }});*/
                  this.signIn()
                  return;
                case "auth/invalid-email":
                  this.setState({ errors: {
                    ...this.state.errors,
                    emailAddress: [message],
                  }});
                  return;
                case "auth/operation-not-allowed":
                case "auth/weak-password":
                  this.setState({ errors: {
                    ...this.state.errors,
                    password: [message],
                  }});
                  return;
                default:
                  // this.props.openSnackbar(message);
                  return;
              }
            })
            .finally(() => {
              this.setState({
                performingAction: false,
              });
              // NOTE - manually adding this in for now to deal with reload issue after signup redirection
              // window.location.reload();
            });
        }
      );
    }
  };

  signInWithAuthProvider = (provider) => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .signInWithAuthProvider(provider)
          .then((user) => {
            this.props.dialogProps.onClose(() => {
              const displayName = user.displayName;
              const emailAddress = user.email;

              this.props.openSnackbar(
                `Signed in as ${displayName || emailAddress}`
              );

              // NOTE - manually adding this in for now to deal with reload issue after signup redirection
              window.location.reload();

            });
          })
          .catch((reason) => {
            console.log("frontend catch", reason);
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              case "auth/account-exists-with-different-credential":
              case "auth/auth-domain-config-required":
              case "auth/cancelled-popup-request":
              case "auth/operation-not-allowed":
              case "auth/operation-not-supported-in-this-environment":
              case "auth/popup-blocked":
              case "auth/popup-closed-by-user":
              case "auth/unauthorized-domain":
                this.props.openSnackbar(message);
                return;

              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      }
    );
  };

  handleKeyPress = (event) => {
    const {
      emailAddress,
      password,
    } = this.state;

    if (
      !emailAddress ||
      !password
    ) {
      return;
    }

    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === "Enter") {
      this.signUp();
    }
  };

  handleExited = () => {
    this.setState(initialState);
  };

  handleEmailAddressChange = (event) => {
    const emailAddress = event.target.value;

    this.setState({
      emailAddress: emailAddress,
    });
  };

  // handleEmailReferralChange = (event) => {
  //   const referralemail = event.target.value;

  //   this.setState({
  //     referralemail: referralemail,
  //   });
  // };

 /* handleEmailAddressConfirmationChange = (event) => {
    const emailAddressConfirmation = event.target.value;

    this.setState({
      emailAddressConfirmation: emailAddressConfirmation,
    });
  };
*/
  handlePasswordChange = (event) => {
    const password = event.target.value;

    this.setState({
      password: password,
    });
  };

  /*handlePasswordConfirmationChange = (event) => {
    const passwordConfirmation = event.target.value;

    this.setState({
      passwordConfirmation: passwordConfirmation,
    });
  };
*/

  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { dialogProps } = this.props;

    const {
      performingAction,
      emailAddress,
      password,
      friendUUID,
      errors,
    } = this.state;

    return (
<Dialog
  fullWidth
  maxWidth="md"
  disableBackdropClick={performingAction}
  disableEscapeKeyDown={performingAction}
  {...dialogProps}
  onKeyPress={this.handleKeyPress}
  onExited={this.handleExited}
  style={{ background: 'linear-gradient(180deg, #4191F1 0%, #812CC9 100%)' }}
>
  <DialogTitle disableTypography>
    <Typography variant="h6"> </Typography>

    <Tooltip title="Close">
      <IconButton
        className={classes.closeButton}
        disabled={performingAction}
        onClick={dialogProps.onClose}
      >
        <CloseIcon />
      </IconButton>
    </Tooltip>
  </DialogTitle>
  
  <DialogContent>
    <Grid container direction="column" spacing={2}>
      <Grid item xs>
        <DialogTitle>
          <Typography variant="h6">Sign in with your Prolific ID</Typography>
        </DialogTitle>
      </Grid>

      <Grid item xs>
        <TextField
          disabled={performingAction}
          error={!!(errors && errors.emailAddress)}
          fullWidth
          helperText={errors && errors.emailAddress ? errors.emailAddress[0] : ""}
          label="Prolific ID"
          placeholder="Your UUID is 24 characters long"
          required
          type="text"
          value={emailAddress}
          variant="outlined"
          InputLabelProps={{ required: false }}
          onChange={this.handleEmailAddressChange}
        />
      </Grid>
      
      <Grid item xs>
        <TextField
          autoComplete="new-password"
          disabled={performingAction}
          error={!!(errors && errors.password)}
          fullWidth
          helperText={errors && errors.password ? errors.password[0] : ""}
          label="Friend's ID (Optional)"
          placeholder="Your friend's UUID is 24 characters long"
          type="text"
          value={password}
          variant="outlined"
          InputLabelProps={{ required: false }}
          onChange={this.handlePasswordChange}
        />
      </Grid>

      <Grid item xs>
        <p>By signing up, I acknowledge that I have read and agree to Xyla's <a href="https://xylafriends.com/terms/" target="_blank">Terms of Use</a> and <a href='https://xylafriends.com/privacy/' target="_blank">Privacy Commitment</a></p>
      </Grid>
    </Grid>
  </DialogContent>

  <DialogActions>
    <Button
      color="primary"
      disabled={
        //check if email and password are both 24 characters long 
        emailAddress.length !== 24 || password.length !== 24 || performingAction
      }
      variant="contained"
      onClick={this.signUp}
    >
      Sign In
    </Button>
  </DialogActions>
</Dialog>

    );
  }
}

SignUpDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignUpDialog);

import React, { Component, useState } from "react";

import PropTypes from "prop-types";

import { withRouter, Switch, Redirect, Route } from "react-router-dom";

import * as firebase from "firebase/app";
import "../../firebase";
import { auth } from "../../firebase";
import authentication from "../../services/authentication";
import RBAC from "../RBAC/RBAC";
import ReferralCapture from "../ReferralCapture/ReferralCapture";
import ReferralFriendRequest from "../ReferralCapture/ReferralFriendRequest2";
import XylaColor from "../XylaColor/XylaColor";
import XylaButton from "../XylaButton/XylaButton";
import LaunchScreen from "../LaunchScreen";
import { H1, H2, P} from "../../containers";
import Container from '@material-ui/core/Container';
import {SetSurveyState} from '../../services/localState';
import Auth from '../../services/authentication';
import { CONTENT, contentClient } from "../../data/contentful";
import VideoPlaceholder from "../../illustrations/video.png";
// import XylaConsentForm from "../Consent/Consent";

// need to update website API --> https://alligator.io/js/web-share-api/

import StickyFooter from "../Footer/Footer";

import ReactPlayer from 'react-player'

import './reactplayer.css'
import { color } from "d3";

import jsonQuery from "json-query";

import CustomizedTimeline from "./Customizedtimeline";
import { Button } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/styles";

const GetStartedButton = withStyles((theme) => ({
  root: {
    color: 'white',
    fontWeight: 600,
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
}))(Button);

class HomePage extends Component {


  constructor(props) {
    super(props)
    this.state = {
      xylauserdata: "",
      xylaadmin:"",
      xylaquiz: "",
      quizstate: "",
      consentfirstName: null,
      consentlastName: null,
      consentdate: null,
      setconsentFirstName: null,
      setconsentLasttName: null,
      consentheartbox: false,
      loading: true,
      content: {},
      newContent: {},
      isPlayVideo: false,
      loaded: false
    }
  }

  GetContentful = async () => {
    const { fields: oldContent } = await contentClient.getEntry(CONTENT.LANDING_PAGE.id);
    const { fields: newContent } = await contentClient.getEntry(CONTENT.NEW_WELCOME.id);
    this.setState({ content: oldContent, newContent: newContent});
  }


  ShareClick = () => {
    console.log('this is a click');
    this.props.history.push("/share");
  };

  AssessmentClick = () => {
    Auth.checkReferralStatus().then(result => {
      if (result) {
        this.props.history.push('/friendship');
      } else {
        this.props.history.push("/survey");
      }
    });
  };

  XylaResultsClick = () => {
    console.log('this is a click');
    const xylauserid = firebase.auth().currentUser.uid;
    console.log(xylauserid);
    this.props.history.push(`/xylagraph/${xylauserid}`);
  };



  


  signInWithEmailLink = () => {
    const { user } = this.props;

    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }
    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        this.props.history.push("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          this.props.openSnackbar(
            `Signed in as ${displayName || emailAddress}`
          );
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              this.props.openSnackbar(message);
              break;

            default:
              this.props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.props.history.push("/");
        });
    } else {
      this.setState({ loading: false });
    }
  };


  GettingUserSurvey = () => {

    const db = firebase.firestore();
    if (!firebase.auth().currentUser) return;
    const docRef = db.collection("survey_temp").doc(firebase.auth().currentUser.uid);
    docRef.get().then((doc) => {
        if (doc.exists) {
            let data = doc.data();
            console.log("Document data:", data);
            var tempvar = jsonQuery('surveydata', {data: data}).value
            const temppagenumber = (JSON.parse(tempvar)).currentPageNo
            const tempdata = (JSON.parse(tempvar)).data
            console.log("Temp Page:", temppagenumber)
            console.log("Temp Data:", tempdata)
            
            window.localStorage.setItem('temppagenumber', JSON.stringify(temppagenumber));
            window.localStorage.setItem('tempdata', JSON.stringify(tempdata));

        } else {
            console.log("No such document!");
        }
    }).catch(function (error) {
        console.log("Error getting document:", error);
    }); 

  }

  GettingOldInvites = () => {
    const db = firebase.firestore();
    if (!firebase.auth().currentUser) return;

    const uid = firebase.auth().currentUser.uid;
    const email = firebase.auth().currentUser.email;

    db.collection('connections')
    .where("receiver_email", '==', email)
    // .where("receiver_activated", '==', 'not_yet_generated')
    .orderBy('timestamp_computer', 'desc')
    .onSnapshot(snapshot => {
      var friendList = snapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data(),
        }
      }).filter(x => x.receiver == 'not_yet_generated');

      for (let inviter of friendList) {
        let oldDoc = db.doc(`connections/${inviter.id}`);
        oldDoc.delete();

        let requestFormat = inviter.sender + '_' + uid;
        let requestData = {
          sender: inviter.sender,
          sender_email: inviter.sender_email,
          receiver: uid,
          receiver_email: email,
          uid_group: [inviter.sender, uid],
          timestamp_computer: inviter.timestamp_computer,
          timestamp_human: inviter.timestamp_human,
          inviteType: inviter.inviteType,
          sender_relation: inviter.sender_relation,
          status: 'Pending',
          status_receiver: 'Pending',
          status_sender: 'Approved',
        }
        db.collection("connections")  
        .doc(requestFormat)
        .set(
          {
            ...requestData,
          },
          { merge: true }
        );
      }
    })
  }

  GettingUserData = () => {
        const db2 = firebase.firestore();
        if (!firebase.auth().currentUser) return;
        const docRef = db2.collection("users").doc(firebase.auth().currentUser.uid);
        docRef.get().then((doc) => {
              this.setState({ loaded: true })
              if (doc.exists) {
                  setTimeout(() => this.setState({ loading: false }), 5000);
                  let data = doc.data();
                  console.log("Document User data:", data); 
                  // const XYLAconsentFirst = data.consentsignconfirm
                  // console.log("XYLAconsentFirst: " + XYLAconsentFirst)
                  this.setState({
                    xylauserdata: "Loaded"
                  })
                  // if (XYLAconsentFirst == "confirmed") {
                  //               this.setState({
                  //                 xylauserdata: "Loaded"
                  //               })
                  //               console.log("Loaded Xyla Member - state set")
                  //         } else {
                  //           this.setState({
                  //             xylauserdata: "NotLoaded"
                  //           })
                  //           console.log("Not found - state set")
                  //         }
                  const XYLAadmin = data.admin
                  if (XYLAadmin == "admin") {
                                this.setState({
                                  xylaadmin: "yes"
                                })
                                console.log("Loaded Admin Xyla Member - yes - state set")
                                window.localStorage.setItem('xylaadminstatus', this.state.xylaadmin)
                          } else {
                            this.setState({
                              xylaadmin: "no"
                            })
                            console.log("Xyla Admin Not found - no - state set")
                            window.localStorage.setItem('xylaadminstatus', this.state.xylaadmin)

                          }
                        
              } else{ 
                console.log("Not yet connected to Xyla member data...");
              }
            }
            )

            const docRef2 = db2.collection("survey_complete").doc(firebase.auth().currentUser.uid);
            docRef2.get().then((doc) => {
                  if (doc.exists) {
                    SetSurveyState('COMPLETE');
                        this.setState({
                                    xylaquiz: "completed"
                                    })
                                    console.log("xyla survey completed")
                              } 
                  else {
                    SetSurveyState('NOT_COMPLETE');
                        this.setState({
                                  xylaquiz: "notcompleted"
                                })
                                console.log("xyla survey not completed")
                              }
                            
                }
                )

            const docRef3 = db2.collection("survey_temp").doc(firebase.auth().currentUser.uid);
            docRef3.get().then((doc) => {
                  if (doc.exists) {
                        this.setState({
                                    quizstate: "inprogress"
                                    })
                                    console.log("xyla survey completed")
                              } 
                  else {
                        this.setState({
                          quizstate: "notstarted"
                                })
                                console.log("xyla survey not completed")
                              }
                            
                }
                )

    };

 myChangeHandlerConsent = (event) => {
      let nam = event.target.name;
      let val = event.target.value;
      this.setState({[nam]: val});
    }

  mySubmitHandlerConsent = (event) => {
      if (this.state.consentfirstName != null && this.state.consentlastName != null && this.state.consentheartbox != null) {
        event.preventDefault();
        let consentfirstName2 = this.state.consentfirstName
        let consentlastName2 = this.state.consentlastName
        let consentdate2 = this.state.consentdate
        console.log("friendName= " + consentfirstName2)
        console.log("memberName= " + consentlastName2)
        console.log("consentdate: " + consentdate2)
            this.onClickSubmitConsent() }
      else {      
        console.log("Incomplete form...")
      }
        }


  onClickSubmitConsent = (event) => {
    let consentfirstName3 = this.state.consentfirstName
    let consentlastName3 = this.state.consentlastName
    let consentdate3 = this.state.consentdate

    const dateNow = new Date(); // Creating a new date object with the current date and time
    const year = dateNow.getFullYear(); // Getting current year from the created Date object
    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const month = // Setting current Month number from current Date object
      monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${monthWithOffset}`
        : monthWithOffset;
    const date =
      dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${dateNow.getUTCDate()}`
        : dateNow.getUTCDate();
    const AutoConsentDate = dateNow; // combining to format for defaultValue or value attribute of material <TextField>
    
    const db3 = firebase.firestore();
    db3.collection("users")
          .doc(firebase.auth().currentUser.uid)
          .set(
            {
              consentFirstName: consentfirstName3,
              consentLastName: consentlastName3,
              consentDateUser: consentdate3,
              consentDateAutomatic: AutoConsentDate,
              consentsignconfirm: "confirmed"
            },
            { merge: true }
          )
          console.log("SUCCESS");
          this.setState({
            xylaconsent: "Consent"
          })
    };


  render() {
    
    const { user, performingAction, dialogs, history } = this.props;

    const { content, newContent, isPlayVideo } = this.state;

    if (user && this.state.xylauserdata == "") {
      this.GettingUserData();
      RBAC();
      ReferralCapture();
      ReferralFriendRequest(history);
      XylaColor();
      this.GettingUserSurvey();
    }


    if (user && this.state.xylauserdata == "Loaded" && this.state.quizstate =="notstarted") {
      // this.GettingUserData();
      RBAC();
      ReferralCapture();
      ReferralFriendRequest();
      XylaColor();
      this.GettingUserSurvey();


      const urlreferral = process.env.REACT_APP_HOMEPAGE + '/?referralcode=' + firebase.auth().currentUser.uid;
      return (
        <div>
          <Container spacing={2} maxWidth="md">
            <H1>Welcome to our assessment</H1>
              
            <P>Here are a few thoughts before you take the journey with us:</P>
              
            <P> 
              The assessment takes about 20 minutes to complete. It’s ideal if you can finish it in one sitting, 
              but you have the option to take a break and return when you have time. Often our first answer is the best answer. 
              The questions are multiple choice, sliders, or short written answers. Slide the slider to indicate your preferred section of the range. Please answer based on how you usually are rather 
              than what you hope your best self would be. 
            </P>
            
            <P>
              Your responses will be kept anonymous. 
            </P>
            
            <P>
              Thank you for helping us learn about friendship!
            </P>
            
            <P>
              - The Xyla Research Team
            </P>
              
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', paddingTop: "20px" }}> 
              <XylaButton title="Start the assessment" buttonfunction={this.AssessmentClick} buttonbackground="linear-gradient(180deg, #4191F1 0%, #812CC9 100%)"/>
            </div>

          </Container>
          <StickyFooter />
        </div>
      )
    }

    ////
    //// BELOW IS THE COMPLETED SECTION
    ////
    if (user && this.state.xylauserdata == "Loaded" && this.state.xylaquiz =="completed") {
      // this.GettingUserData();
      RBAC();
      ReferralCapture();
      ReferralFriendRequest();
      XylaColor();
      this.GettingUserSurvey();
      const urlreferral = process.env.REACT_APP_HOMEPAGE + '/?referralcode=' + firebase.auth().currentUser.uid;
      const redirectroute = firebase.auth().currentUser.uid;
      return (
        <div>
          <Redirect to={`/xylagraph/${redirectroute}`} />
          <StickyFooter />
        </div>
      )
    }

    ////
    //// BELOW IS THE PLEASE CONTINUE / FINISH SECTION
    ////

    if (user && this.state.xylauserdata == "Loaded" && this.state.xylaquiz =="notcompleted") {
      // this.GettingUserData();
      RBAC();
      ReferralCapture();
      ReferralFriendRequest();
      XylaColor();
      this.GettingUserSurvey();

      const urlreferral = process.env.REACT_APP_HOMEPAGE + '/?referralcode=' + firebase.auth().currentUser.uid;
      return (
        <div>
            <H1> <div style={{textAlign: "center"}}> Please finish your Xyla assessment! </div> </H1>
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', paddingTop: "20px" }}> 
              <XylaButton title="Complete assessment" buttonfunction={this.AssessmentClick} buttonbackground="linear-gradient(180deg, #4191F1 0%, #812CC9 100%)"/>
            </div>
          <div style={{position: 'absolute', width:'100%', bottom: 0}}>
            <StickyFooter />
          </div>
        </div>
      )
    }

    ////
    //// BELOW IS THE CONSENT SECTION
    ////

    if (this.state.loading) {
      return <LaunchScreen />
    }

    if (false) {
      return (
        <div>
          <Container spacing={2} maxWidth="md" className="friendship-question">
            <H1>{content?.header1}</H1> 
  
            <P>{content?.body1alt}</P>
            <P>{content?.body2}</P>
  
            <H2>{content?.header2}</H2> 
  
            <P>{content?.body3}</P>
            
            <div className='player-wrapper' style={{display: 'flex', alignItems: 'center', paddingBottom: '2em', }}>
              <ReactPlayer playing light={VideoPlaceholder}  url='https://d1xjfcvc9p2qrh.cloudfront.net/xyla_about_low540.mp4' controls='TRUE' />
            </div>
  
          </Container>
          <StickyFooter />
        </div>
      );
    }

    return (
      <>
        <Container spacing={2} maxWidth="md" className="friendship-question" style={{height:'65vh'}}>
          <H1>{newContent?.headline}</H1> 

          <P dangerouslySetInnerHTML={{__html: this.getNewContent(newContent?.bodyCopy)}}></P>

          <GetStartedButton size="large" variant="contained" onClick={this.handleOpenSignUp}>
            {newContent?.cta}
          </GetStartedButton>
        </Container>
        <StickyFooter />
      </>
    )
  }

  handleOpenSignIn = () => {
    const { openDialog } = this.props;
    openDialog('signInDialog');
  }

  handleOpenSignUp = () => {
    const { openDialog } = this.props;
    openDialog('signUpDialog');
  }

  getNewContent(text ='') {
    return text.split('\n').map((item, key) => {
      return `<span>${item}<br/></span>`
    }).join('')
  }

  componentDidMount() {
    this.signInWithEmailLink();
    this.GettingUserData();
    this.GetContentful();
    this.GettingOldInvites();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if ((newProps.user && !this.props.user) || (this.props.user && newProps.user && newProps.user.uid !== this.props.user.uid)) {
      this.GettingUserData();
    }
  }
}

HomePage.propTypes = {
  user: PropTypes.object,
};

export default withRouter(HomePage);